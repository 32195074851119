:root {
  --button-bg: #86e772;
  --button-active-bg: #86e772;
  --small-button-bg: #919191;
  --small-button-active-bg: #646464;
  --button-text: #ffffff;
  --card: rgba(255, 255, 255, 0.2);
  --accountText: #012033;
  --statusText: #012033;
  --bg-gradient_1: #012033;
  --bg-gradient_2: #012033;
  --gradient_1: rgba(255, 255, 255, 0.2);
  --gradient_2: rgba(255, 255, 255, 0.2);
  --gradient_3: rgba(255, 255, 255, 0.2);
  --success: #86e772;
  --warning: #86e772;
  --error: #ca2f24;
}

@font-face {
  font-family: "Dogica";
  src: url("//db.onlinewebfonts.com/t/57d7ce13093381acee3d40c5a512c770.eot");
  src: url("//db.onlinewebfonts.com/t/57d7ce13093381acee3d40c5a512c770.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/57d7ce13093381acee3d40c5a512c770.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/57d7ce13093381acee3d40c5a512c770.woff") format("woff"), url("//db.onlinewebfonts.com/t/57d7ce13093381acee3d40c5a512c770.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/57d7ce13093381acee3d40c5a512c770.svg#Dogica") format("svg");
}

* {
  font-family: "Dogica", "sans-serif", monospace;
  font-size: 15px;
  font-weight: bold;
  line-height: 150%;
  text-transform: uppercase;
}

html,
body {
  padding: 0;
  margin: 0;
  background: linear-gradient(var(--bg-gradient_1) 0%,
      var(--bg-gradient_2) 100%) fixed;
  scroll-behavior: smooth;
}

.colorGradient {
  background: linear-gradient(to bottom,
      var(--gradient_1) 0%,
      var(--gradient_2) 65%,
      var(--gradient_3) 100%) fixed;
}

.page1 {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.card {
  background-color: var(--card);
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  width: 340px;
  transition: 0.3s;
  margin: 1px;
  position: relative;
  backdrop-filter: blur(20px);
}

@media screen and (max-width: 600px) {
  .card {
margin-top: 5em;
  }
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card_header {
  height: 330px;
  width: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: relative;
}

.card_header_image {
  position: absolute;
  width: 93%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  bottom: 0;
}

.card_body {
  padding: 1px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card_body_view_contract {
  padding-right: 15px;
  padding-left: 15px;
}

.card_footer {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spin {
  animation: item-spin infinite 20s linear;
}

.statusText {
  color: var(--statusText);
  font-weight: normal;
  text-align: center;
}

.accountText {
  color: var(--accountText);
  font-weight: bold;
  font-size: 10px;
}

.ns {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

._90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(-90deg);
  font-size: 10px;
  color: #012033;
}

.button {
  display: inline-block;
  padding: 7px 14px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: var(--button-text);
  background-color: var(--button-bg);
  border: none;
  border-radius: 15px;
  margin-bottom: 2px;
}

button:disabled {
  opacity: 0.5;
}

.button:active {
  background-color: var(--button-active-bg);
}

.small_button {
  display: inline-block;
  padding: 5px 10px;
  font-size: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: var(--button-text);
  background-color: var(--small-button-bg);
  border: none;
  border-radius: 15px;
  margin-bottom: 2px;
}

.small_button:disabled {
  opacity: 0.5;
}

.small_button:active {
  background-color: var(--small-button-active-bg);
}

@keyframes item-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*
========================================================================
========================================================================
========================================================================
========================================================================
========================================================================
========================================================================
*/

.page {

  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background-image: url(/src/assets/back3.jpg);
  background-size: cover;
}

.heading {
  display: flex;
  width: 90%;
  z-index: 3;
  height: 80px;
  align-items: center;
  margin-top: 3em;
  margin-bottom: 1em;
}

@media screen and (max-width: 600px) {
  .heading {
    height: 60px;
    justify-content: space-around;
  }
}

.logo {
  height: inherit;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .logo {
    margin: 0;
  }
}

.market {
  width: 17em;
  border: none;
  border-radius: 10px;

}

.market:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.14), 0 17px 50px 0 rgba(0, 0, 0, 0.14);
}

.mmenu {
  color: #86e772;
  display: flex;
  margin: auto;
  height: inherit;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .mmenu {
    margin: 0;
  }
}


.social {
  height: inherit;
  padding-right: 10px;
}

@media screen and (max-width: 600px) {
  .social {
    padding-right: 0px;
  }
}

.insta {
  height: inherit;
  padding-right: 10px;
}

@media screen and (max-width: 840px) {
  .insta {
    display: none;
    padding-right: 0;
  }
}

.mmenu .icon {
  display: none;
}

@media screen and (max-width: 600px) {
  .mmenu a:not(:first-child) {
    display: none;
  }

  .mmenu a.icon {
    float: right;
    display: flex;
  }
}

@media screen and (max-width: 600px) {
  .mmenu.responsive {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: end;

  }

  .mmenu.responsive .icon {
    height: inherit;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  .mmenu.responsive a {
    float: none;
    display: flex;
    height: inherit;

  }
}

.minted {
  color: #ffffff;
  text-decoration: none;
  margin-right: 20px;
}

.minted:hover {
  color: #86e772;
}

@media screen and (max-width: 840px) {
  .minted {
    margin-right: 0;
  }
}

.fmenu {
  color: #ffffff;
  text-decoration: none;
  margin-right: 20px;
}

.fmenu:hover {
  color: #86e772;
}


.bgd {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  bottom: -21px;

  -webkit-animation: mover 5s infinite alternate;
  animation: mover 5s infinite alternate;
}

.back {
  margin: auto;
  width: 30%;
  min-width: 250px;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(1);
  }

  100% {
    transform: translateY(30px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(30px);
  }
}


.marketplace {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
}

.buttonm {
  display: flex;
  border-radius: 20px;
  color: white;
  padding: 15px 15px;
  margin: 5px 2px;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  background: #8bc34a3d;
  box-shadow: -3px 7px 20px 4px rgb(24 194 177);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.082);
}

.buttonm:hover {

  background: #86e772;
}


._9a0 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(-90deg);
}

.button {
  display: inline-block;
  padding: 7px 10px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: var(--button-text);
  background-color: var(--button-bg);
  border: none;
  border-radius: 15px;
  margin-bottom: 2px;
}

button:disabled {
  opacity: 0.5;
}

.button:active {
  background-color: var(--button-active-bg);
}

.small_button {
  display: inline-block;
  padding: 5px 10px;
  font-size: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: var(--button-text);
  background-color: var(--small-button-bg);
  border: none;
  border-radius: 15px;
  margin-bottom: 2px;
}

.small_button:disabled {
  opacity: 0.5;
}

.small_button:active {
  background-color: var(--small-button-active-bg);
}

@keyframes item-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.about {
  position: relative;
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  color: #ffffff;
  background-image: url(/src/assets/aboutpic.jpg);
  background-size: cover;
}

.vision {
  height: 100%;
}

.info {
  padding: 1em;
  margin: auto;
  text-align: center;
}

.amin {
  display: flex;
  flex-direction: row;
  width: 90%;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media screen and (max-width: 600px) {
  .amin {
    flex-direction: column;
    width: 85%;
    margin-bottom: 1em;
  }
}

.abig {
  width: 20%;
  display: flex;
  flex-direction: column;
  margin-right: 20px;

}

.abig2 {
  width: 40%;
  display: inline-grid;
  grid-template-columns: repeat(2, [col-start] 1fr);
  margin: 2em;

}

@media screen and (max-width: 600px) {
  .abig2 {
    margin: auto;
    width: auto;
  }
}

.asmall {
  width: 40%;
  min-width: 300px;
}

@media screen and (max-width: 600px) {
  .asmall {
    width: 85%;
  }
}



.stbg {
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
}

@media screen and (max-width: 600px) {
  .stbg {
    top: 24px;
    height: 100px;
  }
}

.bbb {
  z-index: 2;
  position: relative;
}

.toad1 {
  border-radius: 10px;
  max-width: 100%;
  max-height: 96%;
}

.maintoadz {
  border-radius: 10px;
  height: 300px;
}

.button1 {
  border-radius: 8px;
  background-color: #5662f6;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  margin: 2px 2px;
  cursor: pointer;
}

.button2 {
  border-radius: 8px;
  background-color: #86e772;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  margin: 2px 2px;
  cursor: pointer;
}

.design {
  position: relative;
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
  background-image: url(/src/assets/back4.jpg);
  background-size: cover;
}

.dmin {
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: center;
  margin-bottom: 1em;
}

@media screen and (max-width: 600px) {
  .dmin {
    flex-direction: column;
  }
}

.cmin {
  display: flex;
  flex-direction: row;
  width: 70%;
}

.dmid {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .dmid {
    width: 85%;
    margin: auto;
  }
}

.dsmall {
  text-align: center;
  width: 50%;
  margin: auto;
  padding-bottom: 1em;
}

@media screen and (max-width: 600px) {
  .dsmall {
    width: auto;
  }
}

.csmall {

  width: 50%;
}

.cmid {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cm {
  width: 40%;
  margin: auto;
}

.discord {
  position: relative;
  min-height: 80vh;
  min-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  color: #012033;
  background-size: contain;
}

.dbig {

  width: 55%;
}

.team {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.teampic {
  max-width: 25%;
  max-height: 70%;
  margin: auto;
  -webkit-animation: mover 5s infinite alternate;
  animation: mover 5s infinite alternate;
}

.teampic:hover {
  transform: skew(-10deg);
}

.joindis {
  display: flex;
  align-items: center;
  height: 80px;
  margin-bottom: 13px;
  justify-content: center;
}

.joindisb {
  display: flex;
  align-items: center;
  height: 80px;
  margin-bottom: 13px;
}

.joindisb:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.footer {
  position: relative;
  min-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #012033;
  color: #ffffff;
  background-image: url(/src/assets/footer2.jpg);
  background-size: cover;
}

.footerin {
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #919191;

}

.footermenu {
  display: flex;
  align-items: center;
  height: 80px;
  margin-bottom: 13px;
}

@media screen and (max-width: 600px) {
  .footermenu {
    flex-direction: column;
  }
}

.footerpic {

  height: 80px;
}






@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0)
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0)
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
  opacity: 0
}

.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}